import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { graphql } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../components/Layout'
import { Post } from '../components/Post'
import SEO from '../components/seo'
import { SubscriptionForm } from '../components/SubscriptionForm'

const Previews = ({ posts }) => (
  <>
    {posts.map(({ node }) => {
      const { title } = node.frontmatter
      const { slug } = node.fields || {}
      return (
        <Typography key={title} gutterBottom color={'textSecondary'}>
          {title}
        </Typography>
      )
    })}
  </>
)
const Posts = ({ posts }) => (
  <>
    {posts.map(({ node }) => {
      return (
        <Post
          key={node.id}
          slug={node.fields.slug}
          title={node.frontmatter.title || node.fields.slug}
          cover={node.frontmatter.cover}
        />
      )
    })}
  </>
)

const mapArrToPosts = arr =>
  arr.map(title => ({
    node: {
      frontmatter: {
        title,
      },
    },
  }))

const Section = ({ header, points }) => (
  <>
    <Typography variant={'overline'} color={'textSecondary'}>
      {header}
    </Typography>
    <List>
      {points.map(point => (
        <ListItem
          key={point}
          divider={points.indexOf(point) !== points.length - 1}
        >
          <ListItemText primary={point} />
        </ListItem>
      ))}
    </List>
  </>
)

export default props => {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
      allMdx: { edges },
    },
    location,
  } = props

  const [showDescription, setShowDescription] = useState(false)
  return (
    <>
      <Layout location={location} title={title}>
        <SEO
          title={'10x Better Workflow'}
          keywords={[`productivity`, `pomodoro`, `workflow`]}
        />
        <Typography variant={'h6'} color={'secondary'}>
          GUIDE
        </Typography>
        <Typography variant={'h3'} color={'secondary'}>
          Building a Sustainable Productivity Workflow
        </Typography>

        <Typography variant={'subtitle1'} color={'secondary'}>
          A Working Set Of Principles I've Found Throughout My 10 Year Journey
        </Typography>
        <br />
        <br />

        <Typography variant={'overline'}>SUBSCRIBE</Typography>
        <SubscriptionForm />
        <br />
        <Typography variant={'overline'}>POSTS</Typography>
        <Posts
          posts={edges.filter(post => {
            return (
              post.node.frontmatter.projects &&
              post.node.frontmatter.projects.includes('pomodoro')
            )
          })}
        />
        <br />
        <Typography variant={'overline'}>COMING LATER</Typography>
        <Previews
          posts={mapArrToPosts([
            'Why The «Pomodoro Habit» Is The Key To Many Other Habits',
            // 'How I Have Powered Up My Pomodoro Routine With Home Automation System',
            'How Pomodoro Muscled Me Up (For Real!)',
            //'How Pomodoro Increased My Productivity',
            //'How I Have Changed Pomodoro Technique Under Myself',
            'How I Was Applying Pomodoro Technique Wrong For the Whole Freaking Year',
            //'Why My Pomodoro Routine Sucks and How To Make It Better',
            '25 Things That Improved In My Life By Using The Pomodoro Technique',
            'Cool Thing About Pomodoro I Want To Try' /* offline button */,
            'My Research — The Science Behind Pomodoro Technique',
            'How Pomodoro Is Connected to Discipline' /* Easy to understand hard to execute */,
            //'Why Broken Pomodoro Is Better Than No Pomodoro' /* It still improves life */,
            //'What Modern Desease Can Be Cured By Pomodoro' /* I Believe Into Putting Limits */,
            //'Pomodoro — It Is Not Only About Better Process' /* I know where the end is */,
            //'Unwrapping Pomodoro Technique For Me, Personally' /* Putting limits, plan ahead, focus on one, know the end etc */,
            'How To Survive The Pomodoro Break?' /* about restoring context */,
          ])}
        />
        <br />
        <Typography
          variant={'overline'}
          style={{
            color: red[500],
          }}
          gutterBottom
        >
          DON'T MISS THE GOOD STUFF. SUBSCRIBE!
        </Typography>
        <SubscriptionForm />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }

    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { released: { eq: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            projects
            cover {
              publicURL
              childImageSharp {
                fixed(width: 240, height: 120) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
